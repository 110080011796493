import React from 'react'
import { Link } from 'react-router-dom'
import './RegistrationLayout.css'

import { LOGO, SITE_NAME } from '@/utils'

const RegistrationLayout = (props) => {
    return (
        <section className="registrationCnt">
            <div className="registration">
                <div className="plBox">
                    <div className="text-center container regInfo">
                        <h2>Register</h2>
                        <h4>{'Create your free account today.'}</h4>
                    </div>
                    {props.children}
                </div>
            </div>
        </section>
    )
}

export default RegistrationLayout
