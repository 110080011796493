import React, { useContext, useEffect } from 'react'
import './Login.css'
import { Link, useHistory } from 'react-router-dom'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import AuthContext from '@/product/context/auth/authContext'
import { handleRedirectInternal, mapData } from '@/product/common/components'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import { Button } from '@material-ui/core'

const LoginComponent = (props) => {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { t } = useTranslation()

    const { login, responseStatus, clearResponse, isAuthenticated } = authContext

    useEffect(() => {
        if (isAuthenticated) {
            handleRedirectInternal(history, '')
        }
    }, [isAuthenticated])

    const formik = useFormik({
        initialValues: props.initialValues,
        validationSchema: props.validationArray,
        onSubmit: (values) => {
            if (values.remember_me) {
                localStorage.email = values.email
                localStorage.password = values.password
                localStorage.remember_me = values.remember_me
            } else {
                delete localStorage.email
                delete localStorage.password
                delete localStorage.remember_me
            }

            login(values)
        },
    })

    const rememberMe = {
        formik: formik,
        data: [
            {
                label: 'Remember Me',
                name: 'remember_me',
                type: 'checkbox',
                placeholder: 'remember_me',
                class: '',
            },
        ],
    }
    const loginInfo = {
        formik: formik,
        data: props.loginInfo,
    }

    useEffect(() => {
        console.log('LOCAL VSTORAGE', localStorage)
    })
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    if (props.onSuccess) {
                        props.onSuccess()
                    } else {
                        handleRedirectInternal(history, 'search')
                    }
                }
            }
        }
    }, [responseStatus])

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">{Object.values(mapData(loginInfo))}</div>
                <div className="d-flex loginActBox justify-content-between align-items-center remebrMe">
                    {props.rememberMe ? Object.values(mapData(rememberMe)) : ''}
                    <Button
                        id="forgotpassword_link"
                        onClick={props.forgetModalChange}
                        className="aPass"
                    >
                        Forgot Password?
                    </Button>
                </div>
                <SecondaryButton id="remember_me" label={t('login')} type="submit" />
                <p className="forPass">
                    Don&apos;t have an account?
                    <Button onClick={props.registerModalChange} className="aPass">
                        Register
                    </Button>
                </p>
            </form>
        </>
    )
}

export default LoginComponent
