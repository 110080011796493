import React, { useContext, useEffect, useState } from 'react'
import PrimaryButton from '../../../../atoms/PrimaryButton'
import SecondaryButton from '../../../../atoms/SecondaryButton'
import {
    handleRedirectInternal,
    mapData,
    formatPhone,
    formatDate,
} from '../../../../../common/components'
import { useFormik } from 'formik'
import EmailValidationComponent from '../../../../species/components/user/EmailValidationComponent'
import PhoneValidationComponent from '../../../../species/components/user/PhoneValidationComponent'
import AuthContext from '../../../../../context/auth/authContext'
import { useHistory } from 'react-router-dom'
import CommonContext from '../../../../../context/common/commonContext'
import { SET_ALERT } from '../../../../../context/alert/alertTypes'
import AlertContext from '../../../../../context/alert/alertContext'
import CustomCommonContext from '../../../../../../custom/context/common/commonContext'
import { useTranslation } from 'react-i18next'
import CustomDialog from '../../../../../components/organisms/Dialog'
import { initialFunction, validationFunction, editValue } from '../FieldFunction'
import Button from '@material-ui/core/Button'
import * as Yup from 'yup'
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google'
import jwtDecode from 'jwt-decode'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import moment from 'moment'
import Cryptos from '../../../../../../product/common/cryptos'
//Don't change cryptos path
const RegisterComponent = (props) => {
    const history = useHistory()
    const { t } = useTranslation()
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { alldata_all_dropdown } = useContext(CustomCommonContext)
    const { register, responseStatus, clearResponse, checkValidation, isAuthenticated } =
        authContext
    const { setAlert } = alertContext
    const commonContext = useContext(CommonContext)
    const [customBox, setCustomBox] = useState(false)
    const { configFeatures, allStates, allCountries, allLocations, allCities } = commonContext
    const arrayValue = props.fieldValue
    const [isGoogle, setIsGoogle] = useState(false)
    const [phoneVerify, setPhoneVerify] = useState(false)
    const [valueLoaded, setValueLoaded] = useState(false)
    const [initialValues, setInitialValues] = useState({})
    const [initialEmptyValues, setInitialEmptyValues] = useState({})
    const [validationArray, setValidationArray] = useState({})
    const [mapDataValue, setMapDataValue] = useState([])
    const [disable, setDisable] = useState(false)
    const [reload, setReload] = useState(false)
    const [emailVerify, setEmailVerify] = useState(false)
    const [toggleDialog, setToggleDialog] = useState(false)

    const changeEmailVerify = () => {
        setEmailVerify(!emailVerify)
    }
    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }
    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    useEffect(() => {
        if (isAuthenticated) {
            handleRedirectInternal(history, '')
        }
    }, [isAuthenticated])

    // Set Iniaital values
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(validationArray),
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values, 'values', props)
            if (values.termCheck != 0) {
                console.log('true')
            } else {
                console.log('false')
            }
            if (props.phoneVerify || props.emailVerify || props.selectVerificationType) {
                if (props.selectVerificationType) {
                    if (
                        (values.phone_verified === 1 &&
                            formik.values.verified_phonenum === formik.values.phone) ||
                        (values.email_verified === 1 &&
                            formik.values.verified_email === formik.values.email)
                    ) {
                        console.log('values inside', values, props)
                        setDisable(true)
                        // values.phone = values.phone.replace(/[^+0-9]/g, '')
                        if (props.submitFunction) {
                            props.submitFunction()
                        } else {
                            if (props.username) {
                                values.username = values.email
                            }
                            if (props.formatPhone && props?.phoneField?.length) {
                                props?.phoneField.map((data) => {
                                    if (values[data]) {
                                        values[data] = formatPhone(values[data])
                                    }
                                })
                            }
                            if (props.toggleOrganization) {
                                if (values.companyname) {
                                    values.organization = 1
                                } else {
                                    values.organization = 0
                                }
                            }
                            register(values)
                        }
                    } else {
                        setToggleDialog(true)
                    }
                } else if (props.phoneVerify) {
                    if (
                        values.phone_verified === 1 &&
                        formik.values.verified_phonenum === formik.values.phone
                    ) {
                        console.log('values inside', values, props)
                        setDisable(true)
                        // values.phone = values.phone.replace(/[^+0-9]/g, '')
                        if (props.submitFunction) {
                            props.submitFunction()
                        } else {
                            if (props.username) {
                                values.username = values.email
                            }
                            if (props.formatPhone && props?.phoneField?.length) {
                                props?.phoneField.map((data) => {
                                    if (values[data]) {
                                        values[data] = formatPhone(values[data])
                                    }
                                })
                            }
                            register(values)
                        }
                    } else {
                        console.log('work')
                        if (props.additionalCondition) {
                            if (values.email.includes('@ekkanoo.com.bh')) {
                                setPhoneVerify(true)
                            } else {
                                setAlert('Invalid Email Format', 'error')
                            }
                        } else if (props.emailValidation) {
                            checkValidation({
                                text: values.email,
                                type: 'email',
                            })
                        } else {
                            setPhoneVerify(true)
                        }
                    }
                } else {
                    if (
                        values.email_verified === 1 &&
                        formik.values.verified_email === formik.values.email
                    ) {
                        console.log('values inside', values, props)
                        setDisable(true)
                        // values.phone = values.phone.replace(/[^+0-9]/g, '')
                        if (props.submitFunction) {
                            props.submitFunction()
                        } else {
                            if (props.username) {
                                values.username = values.email
                            }
                            if (props.formatPhone && props?.phoneField?.length) {
                                let finalVal = { ...values }
                                props?.phoneField.map((data) => {
                                    if (finalVal[data]) {
                                        finalVal[data] = formatPhone(finalVal[data])
                                    }
                                })
                                if (
                                    finalVal?.license_expiry_date &&
                                    finalVal?.license_expiry_date !== ''
                                ) {
                                    finalVal.license_expiry_date = moment(
                                        finalVal.license_expiry_date,
                                    ).format('YYYY-MM-DD')
                                }

                                if (props.hiddenMaskFields && props?.hiddenMaskFields?.length) {
                                    props.hiddenMaskFields.map((data) => {
                                        if (finalVal[data]) {
                                            finalVal[data] = Cryptos.encrypt(finalVal[data])
                                        }
                                    })
                                }

                                register(finalVal)
                            } else {
                                if (
                                    values?.license_expiry_date &&
                                    values?.license_expiry_date !== ''
                                ) {
                                    values.license_expiry_date = moment(
                                        values.license_expiry_date,
                                    ).format('YYYY-MM-DD')
                                }
                                register(values)
                            }
                        }
                    } else {
                        setEmailVerify(true)
                    }
                }
            } else {
                if (props.username) {
                    values.username = values.email
                }
                if (props.formatPhone && props?.phoneField?.length) {
                    props?.phoneField.map((data) => {
                        if (values[data]) {
                            values[data] = formatPhone(values[data])
                        }
                    })
                }

                if (props.formatCustomDate && props?.formatCustomDateFields?.length) {
                    props?.formatCustomDateFields.map((data) => {
                        if (values[data]) {
                            values[data] = formatDate(values[data])
                        }
                    })
                }

                if (props.hiddenMaskFields && props?.hiddenMaskFields?.length) {
                    props.hiddenMaskFields.map((data) => {
                        if (values[data]) {
                            values[data] = Cryptos.encrypt(values[data])
                        }
                    })
                }

                register(values)
            }
            // setDisable(false)
        },
    })

    const termsCondition = {
        formik: formik,
        data: [
            {
                label: t(
                    'Acepto las condiciones de uso y la Información básica de Protección de Datos',
                ),
                name: 'termCheck',
                type: 'checkbox',
                placeholder: 'termCheck',
                class: '',
            },
        ],
    }

    const verifyValidation = Yup.object({
        verify_method: Yup.string().required('Required'),
    })

    const verifyformik = useFormik({
        initialValues: {
            verify_method: '',
        },
        validationSchema: () => verifyValidation,
        onSubmit: (values) => {
            if (values.verify_method) {
                if (values.verify_method === 'email') {
                    setEmailVerify(true)
                } else if (values.verify_method === 'sms') {
                    setPhoneVerify(true)
                }
                setToggleDialog(false)
            }
        },
    })

    let modeOfVerification = {
        formik: verifyformik,
        data: [
            {
                title: t('How would you like to verify your account?'),
                type: 'radio',
                name: 'verify_method',
                className: 'col-12',
                item: [
                    { id: 'sms', description: t('SMS') },
                    { id: 'email', description: t('Email') },
                ],
            },
        ],
    }

    const subLevelOptions = (data) => {
        let valueDrop = alldata_all_dropdown
            .filter((subcat) => {
                if (
                    formik.values[data.sub_field] &&
                    parseInt(subcat.level, 10) === parseInt(data.sub_level, 10)
                ) {
                    if (
                        parseInt(subcat.active, 10) === 1 ||
                        (formik.values[data.name] && formik.values[data.name] === subcat.id)
                    ) {
                        return (
                            parseInt(formik.values[data.sub_field], 10) ===
                            parseInt(subcat.level_id, 10)
                        )
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            })
            .map((subcat) => {
                let subCategoryChanged = {}
                subCategoryChanged.show = subcat.description
                subCategoryChanged.value = subcat.id
                return subCategoryChanged
            })
        return valueDrop
    }

    const subStateOptions = (data) => {
        let valueDrop = allStates
            .filter(
                (state) =>
                    (formik.values.country
                        ? formik.values.country.indexOf(state.countryCode) !== -1
                        : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                    global.ignoreStates.indexOf(state.isoCode) === -1,
            )
            .map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.isoCode
                return busCategoryChanged
            })
        if (global.defaultState) {
            const object1 = valueDrop.find((obj) => obj.show === global.defaultState)
            if (object1) {
                // Remove objects from their current positions
                valueDrop.splice(valueDrop.indexOf(object1), 1)
                valueDrop.unshift(object1)
            }
        }
        console.log(valueDrop, 'drop')
        return valueDrop
    }

    const subCityOptions = (data) => {
        let valueDrop = allCities
            .filter(
                (state) =>
                    (formik.values.country && formik.values.state
                        ? formik.values.country == state.countryCode &&
                          formik.values.state == state.stateCode
                        : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                    global.ignoreCity.indexOf(state.name) === -1,
            )
            .map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            })
        console.log(valueDrop, 'drop')
        return valueDrop
    }

    if (props.populateValidation) {
        useEffect(() => {
            if (Object.keys(validationArray).length)
                formik.validateForm().then((errors) => {
                    if (Object.keys(errors).length) {
                        formik.handleSubmit()
                    }
                })
        }, [validationArray])
    }

    // Check response status is succesuful
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'register') {
                if (props.customPopUp) {
                    setCustomBox(false)
                }
                if (responseStatus.status === 'success') {
                    if (props.onSuccess) {
                        props.onSuccess(responseStatus.data)
                    } else {
                        handleRedirectInternal(history, 'login')
                    }
                }
            } else if (responseStatus.from === 'checkValidation') {
                if (responseStatus.status !== 'success') {
                    setAlert(responseStatus.message, responseStatus.status)
                } else {
                    // formik.touched.last_name = false
                    // formik.touched.address1 = false
                    // formik.touched.phone = false
                    // formik.touched.city = false
                    // formik.touched.state = false
                    // formik.touched.zip = false
                    // window.scrollTo(0, 200)
                    if (props.emailValidation && props.phoneVerify) {
                        setPhoneVerify(true)
                    } else {
                        if (props.customPopUp) {
                            setIsGoogle(true)
                            setCustomBox(true)
                        } else {
                            register(formik.values)
                        }
                    }
                }
                clearResponse()
            }
            setDisable(false)
        }
    }, [responseStatus])

    useEffect(() => {
        if (props.autoRegisterAfterVerify) {
            if (formik.values.phone_verified === 1 || formik.values.email_verified === 1) {
                if (props.customPopUp) {
                    setCustomBox(true)
                    return false
                }
            }

            if (formik.values.phone_verified === 1) {
                formik.submitForm()
            }
            if (formik.values.email_verified === 1) {
                formik.submitForm()
            }
        }
    }, [formik.values.phone_verified, formik.values.email_verified])

    useEffect(() => {
        setValueLoaded(false)
        setInitialValues({})
        setInitialEmptyValues({})
        let custom = props.customInitial
        if (arrayValue.length) {
            let mapValueArray = arrayValue
            const index = null

            let { tempMapData, show } = initialFunction(
                mapValueArray,
                alldata_all_dropdown,
                subLevelOptions,
                allCountries,
                subStateOptions,
                subCityOptions,
                allLocations,
                index,
            )

            setInitialValues({ ...show, ...custom })

            setInitialEmptyValues({ ...custom, ...show })
            setMapDataValue(tempMapData)
            if (tempMapData.length) {
                setValueLoaded(true)
                setReload(!reload)
            }
        }
    }, [arrayValue, props.customInitial])

    useEffect(() => {
        setValidationArray({})

        if (arrayValue.length) {
            let mapValueArray = arrayValue
            let handle = validationFunction(mapValueArray, props.customValidation, t)
            if (
                props.termsCondition &&
                props.customValidation &&
                props.customValidation.termCheck
            ) {
                handle['termCheck'] = props.customValidation.termCheck
            }
            console.log('handle', handle)
            setValidationArray(handle)
        }
    }, [arrayValue, props.customValidation])

    console.log(validationArray, 'valid')

    console.log(mapDataValue, 'mapDataValue')

    useEffect(() => {
        if (mapDataValue.length) {
            let tempMapData = mapDataValue
            tempMapData.map((data) => {
                if (data.type === 'select' && parseInt(data.sub_select, 10) === 1) {
                    if (parseInt(data.sub_level, 10) > 0) {
                        data.options = subLevelOptions(data)
                    }
                }
                if (data.type === 'select' && data.name === 'state') {
                    data.options = subStateOptions(data)
                }
                if (data.type === 'select' && data.name === 'city') {
                    data.options = subCityOptions(data)
                }
                if (data.name == 'resale_certificate') {
                    data.class = `col-12 ${formik.values.tax_number !== '' ? '' : 'd-none'}`
                }
            })
        }
    }, [formik.values])

    useEffect(() => {
        if (formik.values.company_id) {
            formik.values.company_id = props.id
            formik.values.disable = true
        }
    }, [props.id])

    useEffect(() => {
        if (props.additionalFieldUpdate) {
            props.additionalFieldUpdate(formik.values)
        }
    }, [formik.values])

    const responseGoogle = (response) => {
        console.log('glrespose=====>', response)
        const userDetails = jwtDecode(response.credential)
        console.log(userDetails, 'detiii')
        formik.values.google_id = userDetails.jti
        if (formik.values.admin) {
            formik.values.companyname = userDetails.family_name
        }
        formik.values.email = userDetails.email
        formik.values.confirm_email = userDetails.email
        formik.values.first_name = userDetails.given_name
        formik.values.last_name = userDetails.family_name ? userDetails.family_name : ''
        formik.values.password = ''
        formik.values.confirm_password = ''
        checkValidation({
            text: userDetails.email,
            type: 'email',
        })
    }

    const responseFacebook = (response) => {
        console.log('facebook responce=====>', response)
        formik.values.facebook_id = response.id
        formik.values.email = response.email
        formik.values.confirm_email = response.email
        let user_name = response.name.split(' ')
        if (user_name.length > 1) {
            formik.values.first_name = user_name[0]
            formik.values.last_name = user_name[1]
        } else {
            formik.values.first_name = response.name
        }
        formik.values.password = ''
        formik.values.confirm_password = ''

        checkValidation({
            text: response.email,
            type: 'email',
        })
    }

    useEffect(() => {
        if (props.updated) {
            setDisable(false)
        }
    }, [props.updated])
    const autoRegister = () => {
        if (isGoogle) {
            register(formik.values)
        } else {
            formik.handleSubmit()
        }

        setCustomBox(false)
    }
    return (
        <>
            {valueLoaded ? (
                <>
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        {/* {props.googleSignUp ? (
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            render={(renderProps) => (
                                <Button
                                    variant="outlined"
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    color="default"
                                >
                                    <object
                                        aria-label="Google Signin"
                                        data="/assets/svg/google.svg"
                                        type="image/svg+xml"
                                    ></object>
                                    Sign up with Google
                                </Button>
                            )}
                        />
                    ) : null} */}
                        <div className="googleSignUp">
                            {props.googleSignUp ? (
                                <GoogleLogin
                                    text="signup_with"
                                    onSuccess={(credentialResponse) => {
                                        responseGoogle(credentialResponse)
                                    }}
                                    onError={() => {
                                        console.log('Login Failed')
                                    }}
                                    width="96%"
                                />
                            ) : null}

                            {props.facebookSignUp ? (
                                <FacebookLogin
                                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                    fields="name,email"
                                    callback={responseFacebook}
                                    render={(renderProps) => (
                                        <Button
                                            variant="outlined"
                                            onClick={renderProps.onClick}
                                            className="fbSignup"
                                            color="default"
                                        >
                                            <object
                                                aria-label="Facebook Signin"
                                                data="/assets/svg/facebook.svg"
                                                type="image/svg+xml"
                                            ></object>
                                            <span>Sign up with Facebook</span>
                                        </Button>
                                    )}
                                />
                            ) : null}
                        </div>
                        {props.googleSignUp || props.facebookSignUp ? (
                            <b className="text-center d-block my-4">- OR -</b>
                        ) : null}

                        <div className="row">{mapData({ formik, data: mapDataValue })}</div>
                        {props.termsCondition ? Object.values(mapData(termsCondition)) : ''}
                        <div className="regActBox mt-3">
                            <PrimaryButton
                                id="register"
                                label={
                                    props.SubmitBtnLabel
                                        ? props.SubmitBtnLabel
                                        : 'Create my account'
                                }
                                type="submit"
                                disabled={disable}
                            />

                            <PhoneValidationComponent
                                phoneVerify={phoneVerify}
                                formik={formik}
                                setPhoneVerify={setPhoneVerify}
                                changePhoneVerify={changePhoneVerify}
                            />
                            <EmailValidationComponent
                                emailVerify={emailVerify}
                                formik={formik}
                                setEmailVerify={setEmailVerify}
                                changeEmailVerify={changeEmailVerify}
                                verifyStatusChange={false}
                                registerUniqueEmail={props.registerUniqueEmail}
                            />
                        </div>
                    </form>
                </>
            ) : null}

            <CustomDialog
                title={t('Verification')}
                open={toggleDialog}
                function={changeDialogStatus}
                className="verificationModeModal"
                disableBackdropClick
            >
                <form onSubmit={verifyformik.handleSubmit} autoComplete="nofill">
                    {mapData(modeOfVerification)}
                    <div className="actionWrapper">
                        <SecondaryButton
                            label={t('cancel')}
                            btnSize="mr-2"
                            onClick={() => changeDialogStatus()}
                        />
                        <PrimaryButton
                            id="checkout_pay_submit"
                            type="submit"
                            label={t('confirm')}
                        />
                    </div>
                </form>
            </CustomDialog>

            <CustomDialog className="verifyPhone" open={props.customPopUp && customBox}>
                <>
                    <h4 className="vpTitle">
                        {t(
                            'We have sent you an email confirming your registration that will allow you to find the information linked to your account',
                        )}
                    </h4>
                    <br />

                    <div className="actionWrapper secondaryActions">
                        <PrimaryButton
                            id="verifyCode"
                            label={t('SEND CONFIRM EMAIL')}
                            onClick={autoRegister}
                        />
                    </div>
                </>
            </CustomDialog>
        </>
    )
}

export default RegisterComponent
