import React, { useContext } from 'react'
import '../Login/Login.css'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { LOGO, SITE_NAME } from '@/utils'
import ForgetComponent from './forgetComponent'
import CustomCommonContext from '../../custom/context/common/commonContext'
import CustomDialog from '@/custom/components/organisms/CustomDialog'

const ForgotPassword = () => {
    const customCommonContext = useContext(CustomCommonContext)
    const {
        signupModal,
        setSignupModal,
        loginModal,
        setLoginModal,
        forgetPasswordModal,
        setForgetPasswordModal,
    } = customCommonContext

    // Forgot Modal
    const validationArray = Yup.object({
        email: Yup.string().email('invalid email format').required('Required'),
    })

    const initialForgotValues = {
        email: '',
    }
    const forgotValues = [
        {
            label: 'Email address',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12',
            autoFocus: true,
        },
    ]

    const loginModalChange = () => {
        setForgetPasswordModal(false)
        setLoginModal(true)
    }

    return (
        <>
            <CustomDialog
                className="modalView"
                function={() => {
                    setForgetPasswordModal(!forgetPasswordModal)
                }}
                open={forgetPasswordModal}
                title={'Forgot Password'}
            >
                <>
                    <h4 className="subTit">
                        Enter your email address to receive a link to reset your password.
                    </h4>
                    <ForgetComponent
                        validationArray={validationArray}
                        initialValues={initialForgotValues}
                        forgotValues={forgotValues}
                        onSuccess={false}
                    />
                    <p className="forPass">
                        Enter your email address to receive a link to reset your password
                        <a onClick={loginModalChange} className="cursorDecoy aPass">
                            Login here.
                        </a>
                    </p>
                </>
            </CustomDialog>
        </>
    )
}
export default ForgotPassword
