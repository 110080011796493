// for common data
export const COMMON_VALUES = 'COMMON_VALUES'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE'
export const LOGIN_MODAL = 'LOGIN_MODAL'
export const SIGNUP_MODAL = 'SIGNUP_MODAL'
export const REINSTATE_MODAL = 'REINSTATE_MODAL'
export const FORGOT_PASSWORD_MODAL = 'FORGOT_PASSWORD_MODAL'
export const SOCKET_MODAL = 'SOCKET_MODAL'
