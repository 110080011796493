import React, { useState } from 'react'
import './Login.css'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export const loginSchema = () => {
    let [passwordShown, setPasswordShown] = useState(false)
    const { t } = useTranslation()

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    return {
        initialValues: {
            email: localStorage.email ? localStorage.email : '',
            password: localStorage.password ? localStorage.password : '',
            remember_me: localStorage.remember_me ? localStorage.remember_me : false,
        },
        validationArray: Yup.object({
            email: Yup.string().email('Invalid email format').required('Required'),
            password: Yup.string().required('Required'),
        }),
        login: {
            formData: [
                {
                    label: 'Email Address',
                    name: 'email',
                    type: 'email',
                    placeholder: 'Enter your email address',
                    class: 'col-12',
                    autoFocus: true,
                },
                {
                    label: 'Password',
                    name: 'password',
                    type: passwordShown ? 'text' : 'password',
                    placeholder: 'Enter your password',
                    class: 'col-12',
                    endAdornment: passwordShown ? (
                        <span
                            className="material-icons cursorPointer"
                            onClick={togglePasswordVisiblity}
                        >
                            visibility_off
                        </span>
                    ) : (
                        <span
                            className="material-icons cursorPointer"
                            onClick={togglePasswordVisiblity}
                        >
                            visibility
                        </span>
                    ),
                },
            ],
        },
        rememberMe: false,
    }
}
