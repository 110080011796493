import React, { useState, useContext, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { LOGO, SITE_NAME } from '@/utils'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Badge,
    Button,
    Divider,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import AuthContext from '@/product/context/auth/authContext'
import CommonContext from '@/product/context/common/commonContext'
import CartContext from '@/product/context/cart/cartContext'
import AlertContext from '@/product/context/alert/alertContext'
import { handleRedirectInternal, useCustomMediaQuery } from '@/product/common/components'
import './Header.css'
import AutopltContext from '@/product/context/autopilot/autopltContext'
import UserContext from '@/product/context/user/userContext'
import ProductContext from '@/product/context/product/productContext'
import BidHistory from '@/custom/components/organisms/BidHistory'
import LanguageSwitch from '../LanguageSwitch'
import BuynowContext from '@/product/context/buynow/buynowContext'
import PayContext from '@/product/context/payment/payContext'
import CreditContext from '@/product/context/credit/creditContext'
import OfflineContext from '@/product/context/offline/offlineContext'
import ForgotPassword from '@/views/ForgotPassword'
import Loaders from '../Loaders'
import { Popover } from '@material-ui/core'
import { mapData } from '@/product/common/components'
import CustomDialog from '../../organisms/CustomDialog'
import { loginSchema } from '@/views/Login/loginSchema'
import LoginComponent from '@/views/Login/loginComponent'
import ForgetComponent from '@/views/ForgotPassword/forgetComponent'
import Register from '@/views/Registration/dynamic'
import Registration from '@/views/Registration'
import Login from '@/views/Login/login'
import CustomCommonContext from '@/custom/context/common/commonContext'
import PhoneVerify from '@/views/PhoneVerify'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { socket } from '@/product/common/socket'
import StripeCardContext from '@/product/context/stripe/card/cardContext'

const Header = () => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const payContext = useContext(PayContext)
    const cartContext = useContext(CartContext)
    const creditContext = useContext(CreditContext)
    const offlineContext = useContext(OfflineContext)
    const userContext = useContext(UserContext)
    const autopltContext = useContext(AutopltContext)
    const buynowContext = useContext(BuynowContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { setAlert } = alertContext
    const {
        user,
        isAuthenticated,
        loadPendingCount,
        isAdmin,
        cartCount,
        logout,
        switchUser,
        login,
        responseStatus: responseStatusAuth,
        clearResponse: clearResponseAuth,
    } = authContext

    const [state, setState] = useState({
        right: false,
        top: false,
    })

    const formik = useFormik({
        initialValues: {
            user: '',
        },
        onSubmit: (values) => {},
    })
    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)

    const { responseStatus: responseStatusProduct, clearResponse: clearResponseProduct } =
        productContext
    const { responseStatus: responseStatusPayment, clearResponse: clearResponsePayment } =
        payContext

    const { signupModal, setSignupModal, loginModal, setLoginModal, socketModal } =
        customCommonContext

    const { responseStatus: responseStatusCart, clearResponse: clearResponseCart } = cartContext

    const {
        getSkyflowCredit,
        responseStatus: responseStatusUser,
        clearResponse: clearResponseUser,
    } = userContext

    const {
        get_all_payment_method_card_details,
        getAllStripePaymentMethodCards,
        responseStatus: responseStatusCardStripe,
        clearResponse: clearResponseCardStripe,
    } = useContext(StripeCardContext)

    const { responseStatus: responseStatusCredit, clearResponse: clearResponseCredit } =
        creditContext

    const { responseStatus: responseStatusOffline, clearResponse: clearResponseOffline } =
        offlineContext

    const { responseStatus: responseStatusAutoPlt, clearResponse: clearResponseAutoPlt } =
        autopltContext

    const {
        responseStatus: responseStatusBuyNow,
        clearResponse: clearResponseBuyNow,
        getAllCartProducts,
        search_allproducts,
    } = buynowContext

    const { searchValue, setSearchValue, isLoading } = commonContext
    const [searchText, setSearchText] = useState('')
    let [cartProducts, setCartProducts] = useState([])
    const [search, setSearch] = useState({})
    const [forgetPasswordModal, setForgetPasswordModal] = useState(false)
    const [toggleDisconnectDialog, setToggleDisconnectDialog] = useState(false)
    const [socketDisconnect, setSocketDisconnect] = useState(false)

    const changeDisconnectDialogStatus = () => {
        setToggleDisconnectDialog(!toggleDisconnectDialog)
    }
    const switch_user = [
        {
            placeholder: 'Switch User',
            class: 'col-12 pl-0 switchUserDD',
            type: 'select',
            variant: 'standard',
            options: [
                { value: 'user1', show: 'User-Seller' },
                { value: 'user2', show: 'User-Buyer' },
            ],
            name: 'user',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (socket) {
            socket.on('connect', function () {
                console.log('Socket Connected Header')
                setToggleDisconnectDialog(false)
            })
        }
    }, socket)

    useEffect(() => {
        if (formik.values.user) {
            if (formik.values.user === 'user1') {
                login({
                    email: 'abdul.auctionsoftware@gmail.com',
                    password: '12345678',
                })
            } else if (formik.values.user === 'user2') {
                login({
                    email: 'abdul.auctionsoftware+1@gmail.com',
                    password: '12345678',
                })
            }
        }
        if (!isAuthenticated) {
            formik.values.user = ''
        }
    }, [formik.values.user, isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            getAllCartProducts(search)
            getSkyflowCredit()
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (user?.stripe_id && isAuthenticated) {
            getAllStripePaymentMethodCards({
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                customer_id: user.stripe_id,
                object: 'card',
            })
        }
    }, [user?.stripe_id])

    useEffect(() => {
        if (responseStatusBuyNow) {
            setAlert(responseStatusBuyNow.message, responseStatusBuyNow.status)
            clearResponseBuyNow()
        }
    }, [responseStatusBuyNow])

    useEffect(() => {
        setCartProducts(search_allproducts.cartrecords.length ? search_allproducts.cartrecords : [])
    }, [search_allproducts])

    useEffect(() => {
        setCartProducts([])
        return () => {
            setCartProducts([])
        }
    }, [])

    // useEffect(() => {
    //     if (responseStatusUser) {
    //         setAlert(responseStatusUser.message, responseStatusUser.status)
    //         clearResponseUser()
    //     }
    //     if (responseStatusProduct) {
    //         setAlert(responseStatusProduct.message, responseStatusProduct.status)
    //         clearResponseProduct()
    //     }
    //     if (responseStatusAutoPlt) {
    //         setAlert(responseStatusAutoPlt.message, responseStatusAutoPlt.status)
    //         clearResponseAutoPlt()
    //     }
    //     if (responseStatusAuth) {
    //         setAlert(responseStatusAuth.message, responseStatusAuth.status)
    //         clearResponseAutoPlt()
    //     }
    //     if (responseStatusBuyNow) {
    //         setAlert(responseStatusBuyNow.message, responseStatusBuyNow.status)
    //         clearResponseBuyNow()
    //     }
    //     if (responseStatusPayment) {
    //         setAlert(responseStatusPayment.message, responseStatusPayment.status)
    //         clearResponsePayment()
    //     }
    //     if (responseStatusCart) {
    //         setAlert(responseStatusCart.message, responseStatusCart.status)
    //         clearResponseCart()
    //     }
    //     if (responseStatusCredit) {
    //         setAlert(responseStatusCredit.message, responseStatusCredit.status)
    //         clearResponseCredit()
    //     }
    //     if (responseStatusOffline) {
    //         setAlert(responseStatusOffline.message, responseStatusOffline.status)
    //         clearResponseOffline()
    //     }
    // }, [
    //     responseStatusUser,
    //     responseStatusProduct,
    //     responseStatusAutoPlt,
    //     responseStatusAuth,
    //     responseStatusBuyNow,
    //     responseStatusPayment,
    //     responseStatusCart,
    //     responseStatusCredit,
    //     responseStatusOffline,
    // ])

    useEffect(() => {
        if (responseStatusAuth) {
            if (responseStatusAuth.status === 'success') {
                handleRedirectInternal(history, '')
            }
        }
    })

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment') {
                if (responseStatusPayment.status === 'success') {
                    if (responseStatusPayment.data && responseStatusPayment.data.url) {
                        const newWindow = window.open(
                            responseStatusPayment.data.url,
                            '_self',
                            'noopener,noreferrer',
                        )
                        if (newWindow) newWindow.opener = null
                    }
                }
            }
        }
    }, [responseStatusPayment])

    useEffect(() => {
        if (searchValue) {
            handleRedirectInternal(history, ``)
        }
    }, [searchValue])

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setanchormenu(null)
        setanchorcart(null)
        setSearchText('')
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            console.log('history', history, path)
            handleRedirectInternal(history, path)
        }
    }

    const getSearchBarObjet = (value) => {
        return {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                searchbar: {
                    value: value,
                    type: 'like',
                    field: 'p.title,p.desc_proc',
                },
            },
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSearchButtonClick = () => {
        setSearchValue(getSearchBarObjet(searchText))

        setState({ ...state, top: false })

        if (location.pathname != '/') {
            handleRedirectInternal(history, '')
        }
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            setSearchValue(getSearchBarObjet(searchText))
            if (location.pathname != '/') {
                handleRedirectInternal(history, '')
            }
        }
    }

    useEffect(() => {
        if (searchValue && searchValue.filters && searchValue.filters.searchbar) {
            setSearchText(searchValue.filters.searchbar.value)
        }
    }, [searchValue])

    useEffect(() => {
        if (socketModal) {
            // setToggleDisconnectDialog(true)
        }
    }, [socketModal])

    function refreshPage() {
        window.location.reload(false)
    }
    useEffect(() => {
        if (isAuthenticated) {
            let auctionFilter = {
                table: 'buynow',
                field: 'id',
                extraField: '',
                filters: {
                    buynow_autype: {
                        value: '',
                        type: 'in',
                        field: 'b.buynow_autype',
                    },
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'b.paid',
                    },
                    partial: {
                        value: '0',
                        type: 'in',
                        field: 'b.partial',
                    },
                    active: {
                        value: '2',
                        type: 'notin',
                        field: 'b.active',
                    },
                },
            }
            let buyNowFilter = {
                table: 'cart_temp',
                field: 'cart_id',
                extraField: '',
                filters: {
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'crt.paid',
                    },
                },
            }

            loadPendingCount(buyNowFilter, 'buynowCount')

            loadPendingCount(auctionFilter, 'auctionCount')
        }
    }, [isAuthenticated, history.location.pathname])

    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
    })

    useEffect(() => {
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
    }, [cartCount])

    useEffect(() => {
        if (socket) {
            socket.on('connect', () => {
                setSocketDisconnect(false)
            })
            socket.on('disconnect', () => {
                setSocketDisconnect(true)
            })
        }
    }, socket)

    return (
        <>
            {useCustomMediaQuery('(min-width: 1024px)') ? (
                <>
                    {/* DESKTOP NAVIGATION */}
                    <Loaders name="home" isLoading={isLoading} />
                    <header className="mainHeader noPrint">
                        <div
                            className={`${
                                window.location.pathname == '/'
                                    ? 'customContainer'
                                    : 'customContainer'
                            } d-flex justify-content-between align-items-center h-100`}
                        >
                            <div className="headLt">
                                <Link to="/" className="ASlogo">
                                    <img src={LOGO} alt={SITE_NAME} width="200" height="40" />
                                </Link>
                            </div>
                            <div className="headLt">
                                <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                    <input
                                        className="form-control"
                                        type="search"
                                        placeholder={'Search'}
                                        aria-label="Search"
                                        value={searchText}
                                        onChange={handleSearchTextChange}
                                        onKeyDown={handleSearchKeyDown}
                                    />
                                    <Button
                                        id="header_search"
                                        className="btn"
                                        onClick={handleSearchButtonClick}
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </div>
                            </div>
                            <div className="menuRtOtr">
                                <div className="headRt deskNav d-flex justify-content-end align-items-center">
                                    <ul className="d-flex justify-content-start align-items-center">
                                        <li>
                                            {isAuthenticated ? (
                                                user?.admin ? (
                                                    ''
                                                ) : (
                                                    <>
                                                        <Button
                                                            aria-controls="simple-cart"
                                                            aria-haspopup="true"
                                                            onClick={handleCart}
                                                            id="header_shopping_cart"
                                                        >
                                                            <Badge
                                                                badgeContent={count.buynowCount}
                                                                // badgeContent={count.auctionCount}
                                                                color="primary"
                                                            >
                                                                <span className="material-icons">
                                                                    shopping_cart
                                                                </span>
                                                            </Badge>
                                                        </Button>
                                                        <Popover
                                                            anchorEl={anchorcart}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                            id="simple-cart"
                                                            keepMounted
                                                            class={`headerDropMenu ${
                                                                document.body.dir === 'rtl' && 'rtl'
                                                            }`}
                                                            open={Boolean(anchorcart)}
                                                            onClose={handleClose}
                                                        >
                                                            <ul
                                                                className={`headerDropMenu ${
                                                                    document.body.dir === 'rtl' &&
                                                                    'rtl'
                                                                }`}
                                                            >
                                                                <ListItem
                                                                    button
                                                                    id="buynow_cart"
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'buynowcart',
                                                                        )
                                                                    }
                                                                >
                                                                    <Badge
                                                                        badgeContent={
                                                                            count.buynowCount
                                                                        }
                                                                        color="primary"
                                                                    >
                                                                        <span className="material-icons">
                                                                            shopping_bag
                                                                        </span>
                                                                    </Badge>
                                                                    {'Buy now cart'}
                                                                </ListItem>
                                                                {/* <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(history, 'cart')
                                                                    }
                                                                >
                                                                    <Badge
                                                                        badgeContent={
                                                                            count.auctionCount
                                                                        }
                                                                        color="primary"
                                                                    >
                                                                        <span className="material-icons">
                                                                            gavel
                                                                        </span>
                                                                    </Badge>

                                                                    {'Auction cart'}
                                                                </ListItem> */}
                                                            </ul>
                                                        </Popover>
                                                    </>
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </li>
                                    </ul>
                                    <ul className="d-flex justify-content-start align-items-center">
                                        {isAuthenticated ? (
                                            <>
                                                {user?.admin ? (
                                                    <>
                                                        <li>
                                                            <Button
                                                                aria-controls="simple-menu"
                                                                aria-haspopup="true"
                                                                onClick={handleMenu}
                                                                id="header_user_icon"
                                                            >
                                                                <span className="userInitials d-flex align-items-center">
                                                                    {user?.first_name}&nbsp;
                                                                    {user?.last_name}
                                                                    <i className="material-icons-outlined">
                                                                        expand_more
                                                                    </i>
                                                                </span>
                                                            </Button>

                                                            <Popover
                                                                id={'simple-menu'}
                                                                open={Boolean(anchormenu)}
                                                                anchorEl={anchormenu}
                                                                onClose={handleClose}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >
                                                                <ul
                                                                    className={`headerDropMenu ${
                                                                        document.body.dir ===
                                                                            'rtl' && 'rtl'
                                                                    }`}
                                                                >
                                                                    <ListItem
                                                                        button
                                                                        id="profile"
                                                                        onClick={() =>
                                                                            handleClose(
                                                                                history,
                                                                                'account/profile',
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className="material-icons">
                                                                            person
                                                                        </span>
                                                                        {'Profile'}
                                                                    </ListItem>
                                                                    <ListItem
                                                                        button
                                                                        id="logout_button"
                                                                        onClick={() => onLogOut()}
                                                                    >
                                                                        <span className="material-icons">
                                                                            power_settings_new
                                                                        </span>
                                                                        {'Logout'}
                                                                    </ListItem>
                                                                </ul>
                                                            </Popover>
                                                        </li>
                                                    </>
                                                ) : (
                                                    <>
                                                        <li>
                                                            <Button
                                                                id="header_user_detail"
                                                                aria-controls="simple-menu"
                                                                aria-haspopup="true"
                                                                onClick={handleMenu}
                                                            >
                                                                <span className="userInitials d-flex align-items-center">
                                                                    {user?.first_name}{' '}
                                                                    {user?.last_name}
                                                                    <i className="material-icons-outlined">
                                                                        expand_more
                                                                    </i>
                                                                </span>
                                                            </Button>

                                                            <Popover
                                                                id={'simple-menu'}
                                                                open={Boolean(anchormenu)}
                                                                anchorEl={anchormenu}
                                                                onClose={handleClose}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >
                                                                <ul
                                                                    className={`headerDropMenu ${
                                                                        document.body.dir ===
                                                                            'rtl' && 'rtl'
                                                                    }`}
                                                                >
                                                                    <ListItem
                                                                        button
                                                                        onClick={() =>
                                                                            handleClose(
                                                                                history,
                                                                                'auctions/all',
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className="material-icons">
                                                                            gavel
                                                                        </span>
                                                                        {'My bids'}
                                                                    </ListItem>

                                                                    <ListItem
                                                                        button
                                                                        id="profile"
                                                                        onClick={() =>
                                                                            handleClose(
                                                                                history,
                                                                                'account/profile',
                                                                            )
                                                                        }
                                                                    >
                                                                        <span className="material-icons">
                                                                            person
                                                                        </span>
                                                                        {'Profile'}
                                                                    </ListItem>
                                                                    <ListItem
                                                                        button
                                                                        id="logout_button"
                                                                        onClick={() => onLogOut()}
                                                                    >
                                                                        <span className="material-icons">
                                                                            power_settings_new
                                                                        </span>
                                                                        {'Logout'}
                                                                    </ListItem>
                                                                </ul>
                                                            </Popover>
                                                        </li>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <li className="userLoginHr">
                                                    <Button
                                                        id="header_login"
                                                        onClick={() => setLoginModal(true)}
                                                    >
                                                        Login
                                                    </Button>
                                                    /
                                                    <Button
                                                        id="register"
                                                        onClick={() => setSignupModal(true)}
                                                    >
                                                        Register
                                                    </Button>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                    {socketDisconnect && (
                        <p className="network-issue">
                            Network has been disconnected trying to reconnect... or click here to{' '}
                            <span
                                onClick={() => window.location.reload(0)}
                                className="network-reload"
                            >
                                reload
                            </span>
                        </p>
                    )}
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader customContainer d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Link to="/" id="boxbids_logo">
                                <img src={LOGO} alt={SITE_NAME} width="130" height="25" />
                            </Link>
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li>
                                    <Button
                                        id="header_user_search"
                                        onClick={toggleDrawer('top', true)}
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>
                                {/* {isAuthenticated && (
                                    <li className="headCart">
                                        <Link to="/cart" className="headerCrtA">
                                            <span className="material-icons">shopping_cart</span>
                                            <Badge color="primary"></Badge>
                                        </Link>
                                    </li>
                                )} */}
                                <li>
                                    <Button
                                        className="respNavBtn"
                                        id="header_menu"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    {socketDisconnect && (
                        <p className="network-issue">
                            Network has been disconnected trying to reconnect... or click here to{' '}
                            <span
                                onClick={() => window.location.reload(0)}
                                className="network-reload"
                            >
                                reload
                            </span>
                        </p>
                    )}
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder="search"
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button
                                    id="header_searchButton"
                                    className="btn"
                                    onClick={handleSearchButtonClick}
                                >
                                    <span className="material-icons">search</span>
                                </Button>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img src={LOGO} alt={SITE_NAME} height="60" />
                                    </Link>
                                    <Button
                                        id="header_clear"
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul onClick={toggleDrawer('right', false)} className="p-0 m-0">
                                    {isAuthenticated && (
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to={'/account/profile'}
                                            >
                                                <span className="material-icons">person</span>
                                                {'My Account'}
                                            </NavLink>
                                        </ListItem>
                                    )}
                                    {isAuthenticated ? (
                                        user?.admin ? (
                                            <>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/seller/auctions/open"
                                                        onClose={toggleDrawer('right', false)}
                                                    >
                                                        <span className="material-icons">
                                                            gavel
                                                        </span>
                                                        My Auctions
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/seller/lots/open"
                                                        onClose={toggleDrawer('right', false)}
                                                    >
                                                        <span className="material-icons">
                                                            gavel
                                                        </span>
                                                        My Lots
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/seller/templates"
                                                    >
                                                        <span className="material-icons">
                                                            table_chart
                                                        </span>
                                                        Templates
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/seller/reports"
                                                    >
                                                        <span className="material-icons">
                                                            description
                                                        </span>
                                                        Reports
                                                    </NavLink>
                                                </ListItem>
                                            </>
                                        ) : (
                                            <>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/auctions/all"
                                                    >
                                                        <span className="material-icons">
                                                            gavel
                                                        </span>
                                                        {'My bids'}
                                                    </NavLink>
                                                </ListItem>
                                                {/*<ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/auction"
                                                        onClose={toggleDrawer('right', false)}
                                                    >
                                                        <span className="material-icons">
                                                            live_tv
                                                        </span>
                                                        {'Live auctions'}
                                                    </NavLink>
                                                </ListItem>*/}
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/"
                                                        onClose={toggleDrawer('right', false)}
                                                    >
                                                        <span className="material-icons">
                                                            category
                                                        </span>
                                                        {'Products'}
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/purchases/buynow"
                                                    >
                                                        <span className="material-icons">
                                                            shopping_basket
                                                        </span>
                                                        {'My purchases'}
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/watchlist/watchlist"
                                                    >
                                                        <span className="material-icons">
                                                            favorite
                                                        </span>
                                                        {'Favorites'}
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/payments/transactions"
                                                    >
                                                        <span className="material-icons">
                                                            swap_vert
                                                        </span>
                                                        {'My transactions'}
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/payments/saved_cards"
                                                    >
                                                        <span className="material-icons">
                                                            account_balance_wallet
                                                        </span>
                                                        Card Details
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/invoices/all"
                                                    >
                                                        <span className="material-icons">
                                                            receipt
                                                        </span>
                                                        {'My Invoices'}
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/saved_search"
                                                    >
                                                        <span className="material-icons">
                                                            bookmark
                                                        </span>
                                                        {'My saved searches'}
                                                    </NavLink>
                                                </ListItem>
                                            </>
                                        )
                                    ) : (
                                        <>
                                            <ListItem button onClick={() => setLoginModal(true)}>
                                                <span className="material-icons">login</span>
                                                Login
                                            </ListItem>
                                            <ListItem button onClick={() => setSignupModal(true)}>
                                                <span className="material-icons">
                                                    app_registration
                                                </span>
                                                Register
                                            </ListItem>
                                        </>
                                    )}
                                </ul>

                                {/*<ul className="navRespLinks mt-0">
                                    <ListItem button>
                                        <NavLink ActiveclassName="active" to="/contact">
                                            <span className="material-icons">contact_support</span>
                                            {'Contact'}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink ActiveclassName="active" to="/about">
                                            <span className="material-icons">info</span>
                                            {'About'}
                                        </NavLink>
                                    </ListItem>
                                </ul>*/}

                                {isAuthenticated && (
                                    <ListItem className="" button onClick={() => onLogOut()}>
                                        <span className="material-icons">power_settings_new</span>
                                        {'Logout'}
                                    </ListItem>
                                )}
                                <ul className="navRespLinks wrappfixBtn">
                                    <ListItem button>
                                        <p className="m-0">
                                            {' '}
                                            © {new Date().getFullYear()}&nbsp;{' '}
                                            <span className="fsl"> {SITE_NAME} </span>,
                                            {' LLC. All rights reserved'}
                                        </p>
                                    </ListItem>
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            <BidHistory />
            <Login />
            <ForgotPassword />
            <Registration />
            <PhoneVerify />
            <CustomDialog
                title={'Connection Disrupted'}
                open={toggleDisconnectDialog}
                disableBackdropClick={true}
                function={changeDisconnectDialogStatus}
            >
                <h6 className="mb-1">{`It looks like your internet service or browser has been disconnected.`}</h6>
                <h6>{`Please refresh your browser.`}</h6>
                <div className="actionWrapper">
                    <PrimaryButton type="button" onClick={() => refreshPage()} label={'RELOAD'} />
                </div>
            </CustomDialog>
        </>
    )
}
export default Header
