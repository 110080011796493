import React, { useEffect, useContext, useState, Suspense } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Loaders from '@/custom/components/molecules/Loaders'
import Header from '@/custom/components/molecules/Header'
import Footer from '@/custom/components/molecules/Footer'
import RegistrationDynamic from '@/views/Registration/dynamic'

const UserView = React.lazy(() => import('@/views/Seller/Organization/UserView'))
const SellerMyAuctions = React.lazy(() => import('@/views/Seller/Dashboard/AuctionLots/dynamic'))
const AuctionType = React.lazy(() => import('@/views/Seller/PostProject/AuctionType'))
const Bank = React.lazy(() => import('@/views/Seller/Dashboard/Bank'))
const SellerListing = React.lazy(() => import('@/views/Seller/Dashboard/Listing/dynamic'))
const Reports = React.lazy(() => import('@/views/Seller/Report'))
const MyCardDetails = React.lazy(() => import('@/views/Seller/Dashboard/MyCardDetails'))
const CreateLot = React.lazy(() => import('@/views/Seller/PostProject/CreateLot'))
const Login = React.lazy(() => import('@/views/Login/login'))
const Home = React.lazy(() => import('@/views/Home'))
const ForgotPassword = React.lazy(() => import('@/views/ForgotPassword'))
const ResetPassword = React.lazy(() => import('@/views/ResetPassword'))
const Search = React.lazy(() => import('@/views/Search'))
const MyAuctions = React.lazy(() => import('@/views/Dashboard/MyAuctions'))
const Registration = React.lazy(() => import('@/views/Registration'))
const Profile = React.lazy(() => import('@/views/Dashboard/Profile/index'))
const ChangePassword = React.lazy(() => import('@/views/Dashboard/Profile/ChangePassword'))
const MyInvoices = React.lazy(() => import('@/views/Dashboard/MyInvoices'))
const MyDeposits = React.lazy(() => import('@/views/Dashboard/MyDeposits'))
const MyTransactions = React.lazy(() => import('@/views/Dashboard/MyTransactions'))
const Invoice = React.lazy(() => import('@/views/Invoice'))
const DepositInvoice = React.lazy(() => import('@/views/DepositInvoice'))
const LiveAuction = React.lazy(() => import('@/views/ProductView/LiveAuction'))
const ProductViewInvite = React.lazy(() => import('@/views/ProductView/ProductView_Invite'))
const Cart = React.lazy(() => import('@/views/Cart'))
const BuynowCart = React.lazy(() => import('@/views/BuyNowCart'))
const Checkout = React.lazy(() => import('@/views/Checkout'))
const PaymentSuccess = React.lazy(() => import('@/views/Checkout/PaymentSuccess'))
const MyWallet = React.lazy(() => import('@/views/Dashboard/MyWallet'))
const MySubscription = React.lazy(() => import('@/views/Seller/Dashboard/MySubscription'))
const ErrorPage = React.lazy(() => import('@/views/ErrorPage'))
const Auction = React.lazy(() => import('@/views/Auction'))
const VerifyEmail = React.lazy(() => import('@/views/VerifyEmail'))
const AuctionTemplates = React.lazy(() => import('@/views/Seller/Template/Auction/dynamic'))
const ProductTemplates = React.lazy(() => import('@/views/Seller/Template/Product/dynamic'))
const Notifications = React.lazy(() => import('@/views/Dashboard/Notifications'))
const MyAddress = React.lazy(() => import('@/views/Dashboard/MyAddress'))
const ContactUs = React.lazy(() => import('@/views/ContactUs'))
const StaticPage = React.lazy(() => import('@/views/Static'))
const SavedSearch = React.lazy(() => import('@/views/Dashboard/SavedSearch'))
const RosoomResponse = React.lazy(() => import('@/views/Rosoom/Response'))
const MyCard = React.lazy(() => import('@/views/Dashboard/MyCardPaymentMethod'))
// const MyCard = React.lazy(() => import('@/views/Dashboard/MySkyflowCard'))
const MyBank = React.lazy(() => import('@/views/Dashboard/MyBank'))
const ProductViewPage = React.lazy(() => import('@/views/ProductView'))

const Message = React.lazy(() => import('@/views/Message'))
const SwitchUser = React.lazy(() => import('@/views/SwitchUser'))

const Routes = () => {
    /*  const [sta, useSta] = useState([])
    let data = {
        limit: 12,
        page: 1,
        orderby: 'p.id, desc',
        order: '',
        filters: {
            category: {
                value: [],
                type: 'array',
                field: 'it.categoryTypeId',
            },
            searchbar: {
                value: '',
                type: 'like',
                field: 'p.title,p.desc_proc',
            },
        },
    }
    let resetData = {
        limit: 12,
        page: 1,
        orderby: 'p.id, desc',
        order: '',
        filters: {
            category: {
                value: [],
                type: 'array',
                field: 'it.categoryTypeId',
            },
            searchbar: {
                value: '',
                type: 'like',
                field: 'p.title,p.desc_proc',
            },
        },
    }
    const getSearch = (e) => {
        console.log('search###', e)
        useSta(e)
    }
     ```<Search
                            showItems={true}
                            filters={true}
                            sortby={true}
                            limit={true}
                            search={{ data, resetData }}
                            getSearch={getSearch}
     />```
    useEffect(() => {
        console.log('@#$sta', sta)
    }, [sta])*/

    return (
        <>
            <Header />
            <Suspense fallback={<Loaders name="home" isLoading={true} />}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login">
                        <Home login={true} />
                    </Route>
                    <Route exact path="/switch" component={SwitchUser} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/reset_password/:token" component={ResetPassword} />
                    <Route
                        exact
                        path="/buyer_registration/:token"
                        component={RegistrationDynamic}
                    />
                    <Route exact path="/registration">
                        <Registration multiform={false} />
                    </Route>
                    <Route exact path="/otpredirect" component={PaymentSuccess} />
                    {/* <Route exact path="/search">
                        <Search showItems={true} filters={true} sortby={true} limit={true} />
                    </Route> */}
                    <Route exact path="/search" component={Home} />
                    <Route exact path="/liveAuction/:aid" component={LiveAuction} />
                    <Route exact path="/auction" component={Auction} />
                    <Route exact path="/invite" component={ProductViewInvite} />
                    <Route exact path="/contact" component={ContactUs} />
                    <Route exact path="/saved_search" component={SavedSearch} />
                    <Route exact path="/verify_email/:token" component={VerifyEmail} />
                    <PrivateRoute exact path="/buynowcart" component={BuynowCart} />
                    <PrivateRoute exact path="/checkout/:type/:cart_id" component={Checkout} />
                    {/* <PrivateRoute exact path="/cart" component={Cart} /> */}
                    <PrivateRoute exact path="/auctions/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/watchlist/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/purchases/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/account/profile" component={Profile} />
                    <PrivateRoute exact path="/account/change" component={ChangePassword} />
                    <PrivateRoute exact path="/account/notifications" component={Notifications} />
                    <PrivateRoute exact path="/account/myaddress" component={MyAddress} />
                    {/* <PrivateRoute exact path="/company_profile" component={CompanyProfile} /> */}
                    <PrivateRoute exact path="/invoices/:action" component={MyInvoices} />
                    <PrivateRoute exact path="/payments/transactions" component={MyTransactions} />
                    <PrivateRoute exact path="/invoice/:id" component={Invoice} />
                    <PrivateRoute exact path="/depositinvoice/:id" component={DepositInvoice} />
                    <PrivateRoute exact path="/deposits/:action" component={MyDeposits} />
                    <PrivateRoute exact path="/payments/saved_cards" component={MyCard} />
                    <PrivateRoute exact path="/payments/bank_details" component={MyBank} />
                    <PrivateRoute exact path="/wallet" component={MyWallet} />

                    <Route exact path="/productView/:pid" component={ProductViewPage} />
                    <PrivateRoute exact path="/messages" component={Message} />
                    <Route
                        exact
                        path={[
                            '/about',
                            '/services',
                            '/help',
                            '/guidelines',
                            '/privacy_policy',
                            '/terms',
                            '/pickup_instruction',
                            '/how_it_works',
                            '/privacy',
                            '/product_condition',
                            '/faq',
                        ]}
                        component={StaticPage}
                    />

                    <Route exact path="*" component={ErrorPage} />
                </Switch>
                <Footer />
            </Suspense>
            {/* <Suspense fallback={<Loaders name="home" />}> */}
            {/* </Suspense> */}
        </>
    )
}
export default withRouter(Routes)
