import React, { useContext, useEffect } from 'react'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import '../Login/Login.css'
import { mapData, handleRedirectInternal } from '@/product/common/components'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import UserContext from '@/product/context/user/userContext'

const ForgetComponent = (props) => {
    const userContext = useContext(UserContext)
    const { forgotPassword, responseStatus, clearResponse } = userContext
    const history = useHistory()

    const formik = useFormik({
        initialValues: props.initialValues,
        validationSchema: props.validationArray,
        onSubmit: (values) => {
            forgotPassword(values)
        },
    })

    const forgotValues = {
        formik: formik,
        data: props.forgotValues,
    }
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'forgotPassword') {
                if (responseStatus.status === 'success') {
                    if (props.onSuccess) {
                        props.onSuccess()
                    } else {
                        handleRedirectInternal(history, '')
                    }
                    clearResponse()
                }
            }
        }
    }, [responseStatus])

    return (
        <form onSubmit={formik.handleSubmit} autoComplete="nofill" className="w-100">
            <div className="row">{Object.values(mapData(forgotValues))}</div>{' '}
            <PrimaryButton
                id="send_reset_password_link"
                type="submit"
                label={'SEND RESET PASSWORD LINK'}
            />
        </form>
    )
}
export default ForgetComponent
