import React, { useState, useContext, useEffect } from 'react'
import '../Login/Login.css'
import * as Yup from 'yup'
import CustomDialog from '@/custom/components/organisms/CustomDialog'
import { mapData } from '@/product/common/components'
import { useFormik } from 'formik'
import PhoneValidationComponent from '@/product/components/species/components/user/PhoneValidationComponent'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import UserContext from '@/product/context/user/userContext'
import AuthContext from '@/product/context/auth/authContext'

const PhoneVerify = () => {
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const { updateProfile, responseStatus } = userContext
    const { user, isAuthenticated, loadUser } = authContext

    const [phonePopup, setPhonePopup] = useState(false)

    const changePhonePopup = () => {
        setPhonePopup(!phonePopup)
    }

    const [phoneVerify, setPhoneVerify] = useState(false)

    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }

    const validationArray = Yup.object({
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            phone_verified: 0,
            phone: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            console.log('i am phone submit ', values)
            if (
                values.phone_verified === 1 &&
                formik.values.verified_phonenum === formik.values.phone &&
                isAuthenticated
            ) {
                values.id = user.id
                updateProfile(values, false)
            } else if (values.phone) {
                setPhoneVerify(true)
            }
        },
    })

    const phoneValues = [
        {
            autoFocus: true,
            label: 'Phone',
            name: 'phone',
            type: 'phone',
            placeholder: 'Enter your phone',
            class: 'col-12',
            formik: formik,
            disabled: false,
        },
    ]

    useEffect(() => {
        if (isAuthenticated && !user.phone) {
            setPhonePopup(true)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'updateProfile') {
                if (responseStatus.status === 'success') {
                    setPhonePopup(false)
                    loadUser()
                }
            }
        }
    }, [responseStatus])

    return (
        <>
            <CustomDialog
                className="modalView"
                function={changePhonePopup}
                reason="backdropClick"
                open={phonePopup}
                title={'Add Phone Number'}
            >
                <>
                    <h4 className="subTit">Enter your phone number to validate your account.</h4>
                    <div className="row bidding-row">{Object.values(mapData(phoneValues))}</div>
                    <p className="forPass"></p>
                    <div className="actionWrapper">
                        <PrimaryButton
                            id="submit"
                            onClick={formik.handleSubmit}
                            type="button"
                            label="Confirm"
                        />
                    </div>
                </>
            </CustomDialog>
            <PhoneValidationComponent
                phoneVerify={phoneVerify}
                formik={formik}
                setPhoneVerify={setPhoneVerify}
                changePhoneVerify={changePhoneVerify}
            />
        </>
    )
}
export default PhoneVerify
