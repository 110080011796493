import React, { useState, useContext, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Divider } from '@material-ui/core'
import { handleRedirectInternal } from '@/product/common/components'
import AuthContext from '@/product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import LoginComponent from './loginComponent'
import { loginSchema } from './loginSchema'
import SEO from '@/utils/SEO'
import './Login.css'
import * as Yup from 'yup'
import CustomCommonContext from '../../custom/context/common/commonContext'
import CustomDialog from '@/custom/components/organisms/CustomDialog'
import ForgetComponent from '@/views/ForgotPassword/forgetComponent'

const Login = () => {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const vData = loginSchema()
    const { t } = useTranslation()

    const { isAuthenticated } = authContext
    const customCommonContext = useContext(CustomCommonContext)
    const {
        signupModal,
        setSignupModal,
        loginModal,
        setLoginModal,
        forgetPasswordModal,
        setForgetPasswordModal,
    } = customCommonContext

    const handleredirection = () => {
        // handleRedirectInternal(history, '')
    }
    // const [forgetPasswordModal, setForgetPasswordModal] = useState(false)

    useEffect(() => {
        if (isAuthenticated) {
            handleredirection()
        }
    }, [isAuthenticated])

    const forgetModalChange = () => {
        console.log('forgetModalChange')
        setLoginModal(false)
        setForgetPasswordModal(true)
    }

    const loginRedirection = () => {
        setLoginModal(false)
    }
    const registerModalChange = () => {
        setLoginModal(false)
        setSignupModal(true)
    }

    return (
        <>
            <CustomDialog
                className="modalView"
                function={() => {
                    setLoginModal(!loginModal)
                }}
                open={loginModal}
                title={'Login'}
            >
                <LoginComponent
                    initialValues={vData.initialValues}
                    validationArray={vData.validationArray}
                    rememberMe={true}
                    loginInfo={vData.login.formData}
                    onSuccess={loginRedirection}
                    forgetModalChange={forgetModalChange}
                    registerModalChange={registerModalChange}
                />
            </CustomDialog>
        </>
    )
}

export default Login
