import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import './Footer.css'
import AutopltContext from '@/product/context/autopilot/autopltContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LOGO, SITE_NAME } from '@/utils'
import { mapData } from '@/product/common/components'
import { Link } from 'react-router-dom'

const Footer = () => {
    const autopltContext = useContext(AutopltContext)

    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            addCustomer(values)
        },
    })

    const subscribe = [
        {
            label: 'Subscribe to our mailing list',
            placeholder: 'Enter your email address',
            class: 'subsInp',
            type: 'text',
            variant: 'filled',
            name: 'email',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusAutoPlt) {
            if (responseStatusAutoPlt.from === 'addCustomer') {
                if (responseStatusAutoPlt.status === 'success') {
                    formik.values.email = ''
                }
            }
        }
    }, [responseStatusAutoPlt])

    return (
        <footer className="noPrint">
            <div className="footCnt ">
                <div className="customContainer ">
                    <div className="footBody">
                        <div className="footLt">
                            <Link to="/" className="ASlogo">
                                <object data={LOGO} type="image/svg+xml" alt={SITE_NAME}></object>
                            </Link>
                        </div>
                        <div className="footLinks">
                            <ul>
                                <li className="footLinkHdr">
                                    <h2>Customer Service</h2>
                                </li>
                                <li>
                                    <Link id="footer_termsandcondition" to="/terms">
                                        {'Terms and Conditions'}
                                    </Link>
                                </li>
                                <li>
                                    <Link id="footer_delivery" to="/faq">
                                        {'FAQ'}
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li className="footLinkHdr">
                                    <h2>General</h2>
                                </li>
                                <li>
                                    <Link id="footer_contactus" to="/contact">
                                        {'Contact Us'}
                                    </Link>
                                </li>
                                <li>
                                    <Link id="footer_privacypolicy" to="/product_condition">
                                        {'Product Conditions'}
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li className="footLinkHdr">
                                    <h2>Contact Us</h2>
                                </li>
                                <li>
                                    <object
                                        type="image/svg+xml"
                                        data="/assets/svg/email.svg"
                                    ></object>

                                    <a href="mailto:help@boxbids.com">help@boxbids.com</a>
                                </li>
                                <li>
                                    <object
                                        type="image/svg+xml"
                                        data="/assets/svg/phone.svg"
                                    ></object>
                                    <a href="tel:8643002437">+1 (864) 300-2437</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p className="footCopyright text-center px-4" dir="ltr">
                    © {new Date().getFullYear()} &nbsp; <span className="fsl"> {SITE_NAME} </span>,
                    {' LLC. All rights reserved'}
                </p>
            </div>
        </footer>
    )
}

export default Footer
