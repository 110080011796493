import React, { useContext, useState, useEffect } from 'react'
import CustomDialog from '../../../../components/organisms/Dialog'
import PrimaryButton from '../../../atoms/PrimaryButton/index'
import Button from '@material-ui/core/Button'
import UserContext from '../../../../context/user/userContext'

import { handleRedirectInternal, mapData } from '../../../../common/components'
import Loaders from '../../../molecules/Loaders'
import { useTranslation } from 'react-i18next'
export default function PhoneValidationComponent(props) {
    const emailVerify = props.emailVerify
    const changeEmailVerify = props.changeEmailVerify
    const formik = props.formik
    const setEmailVerify = props.setEmailVerify
    const { t } = useTranslation()
    const [disable, setDisable] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const userContext = useContext(UserContext)

    const {
        sendEmailVerifyCode,
        verifyEmailVerifyCode,
        responseStatus: responseStatusUser,
    } = userContext

    const sendVerifyCode = () => {
        setIsloading(true)
        sendEmailVerifyCode({
            email: formik.values.email,
            isRegister: formik.values.isRegister,
            isForgotPassword: formik.values.isForgotPassword,
            registerUniqueEmail: props.registerUniqueEmail ? true : false,
        })
    }

    const verifyPhoneCode = () => {
        setIsloading(true)
        verifyEmailVerifyCode(
            {
                email: formik.values.email,
                verify_code: formik.values?.verify_code,
            },
            props.noToken || false,
        )
    }

    const verifyPhone = [
        {
            label: 'Verification Code',
            placeholder: 'Enter Verification Code',
            class: 'col-12',
            type: 'otpInput',
            name: 'verify_code',
            formik: formik,
            separator: ' ',
            placeholder: '000000',
        },
    ]

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.status === 'success') {
                if (responseStatusUser.from === 'sendEmailVerifyCode') {
                    setTimeout(() => {
                        setIsloading(false)
                    }, 500)
                    formik.setFieldValue('email_verifysent', 1)
                }
                if (responseStatusUser.from === 'verifyEmailVerifyCode') {
                    setTimeout(() => {
                        setIsloading(false)
                    }, 500)
                    formik.setFieldValue('email_verified', 1)
                    formik.setFieldValue('verified_email', formik.values.email)
                    formik.setFieldValue('verify_code', '')
                    if (props.profileUpdate) {
                        props.profileUpdate()
                    } else {
                        if (props.verifyStatusChange) {
                            formik.setFieldValue('status', 'active')
                        }
                        if (props.nextStep) {
                            props.nextStep()
                        }
                    }
                    setEmailVerify(false)
                }
            } else {
                if (
                    responseStatusUser.status === 'error' &&
                    responseStatusUser?.message?.includes('Registration Failed') &&
                    props?.registerUniqueEmail
                ) {
                    changeEmailVerify()
                }
                formik.setFieldValue('verify_code', '')
                setTimeout(() => {
                    setIsloading(false)
                }, 500)
            }
        }
        setDisable(false)
    }, [responseStatusUser])

    useEffect(() => {
        if (formik.values.email_verifysent) {
            formik.setFieldValue('email_verifysent', 0)
            formik.setFieldValue('email_verified', 0)
            if (props.verifyStatusChange) {
                formik.setFieldValue('status', 'email_unverified')
            }
        }
    }, [formik.values.email])

    return (
        <>
            <CustomDialog
                className="verifyPhone"
                // title={'Verify Phone'}
                maxWidth={'sm'}
                open={emailVerify}
                handleClose={() => changeEmailVerify()}
                // function={changeEmailVerify}
            >
                {isLoading ? (
                    <Loaders name="home" isLoading={isLoading} />
                ) : (
                    <>
                        {formik.values.email_verifysent === 1 ? (
                            <>
                                <h4 className="vpTitle">
                                    {t('We have sent a confirmation code to')} {formik.values.email}
                                    .
                                </h4>
                                <br />
                            </>
                        ) : (
                            <h4 className="vpbTitle">
                                {t('A confirmation code will be send to')}{' '}
                                <span>{formik.values.email}.</span>
                                <div className="clearfix"></div>{' '}
                                {t('Please confirm if your email is correct')}.
                            </h4>
                        )}

                        {formik.values.email_verifysent === 1 && (
                            <>
                                <div className="row phVerInp mt-2">
                                    {Object.values(mapData(verifyPhone))}
                                </div>
                                <span className="phVer mb-2 d-block">
                                    * {t('Your code will expire in 5 minutes')}.
                                </span>
                                <PrimaryButton
                                    id="phoneCode"
                                    onClick={verifyPhoneCode}
                                    label={t('VERIFY CODE')}
                                />
                            </>
                        )}
                        <div className="actionWrapper secondaryActions">
                            {/* <Button onClick={() => changeEmailVerify()}>{'Cancel'}</Button> */}
                            {formik.values.email_verifysent === 1 ? (
                                <Button
                                    id="resend_sms"
                                    disabled={disable}
                                    className="resendSms"
                                    onClick={() => {
                                        setDisable(true)
                                        sendVerifyCode()
                                    }}
                                >
                                    {t('RESEND EMAIL')}
                                </Button>
                            ) : (
                                <PrimaryButton
                                    id="verifyCode"
                                    label={t('SEND EMAIL')}
                                    onClick={() => sendVerifyCode()}
                                />
                            )}
                        </div>
                    </>
                )}
            </CustomDialog>
        </>
    )
}
