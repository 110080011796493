import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import './index.css'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CommonContext from '@/product/context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import ProductContext from '@/product/context/product/productContext'
import Loaders from '@/custom/components/molecules/Loaders'
import { currencyFormat, maskEmailFront, dateTimeFormatFunction } from '@/custom/common/components'
import AuthContext from '@/product/context/auth/authContext'
import { Button } from '@material-ui/core'
const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const BidHistory = (props) => {
    const classes = useStyles()
    const [bidPopUp, setBidPopUp] = React.useState({
        popup: false,
        id: 0,
    })
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated } = authContext
    const [isLoading, setIsLoading] = useState(false)
    let [viewProduct, setViewProduct] = useState([])

    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const { bidHistoryValue, clearBidHistoryValue } = commonContext
    const { search_allbidhistory, getAllBidHistory } = productContext

    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        id: 0,
    })

    useEffect(() => {
        if (bidHistoryValue) {
            setSearch({ ...search, id: bidHistoryValue, page: 1 })
            setBidPopUp({ popup: true, id: bidHistoryValue })
            clearBidHistoryValue()
        }
    }, [bidHistoryValue])

    useEffect(() => {
        setViewProduct([])
        setIsLoading(true)
        if (search.id !== 0) {
            getAllBidHistory(search)
        }
    }, [search])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
        setViewProduct(search_allbidhistory.records.length ? search_allbidhistory.records : [])
    }, [search_allbidhistory])

    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={bidPopUp.popup}
            className={`${classes.modal} customModal`}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={bidPopUp.popup}>
                <div className="bidHistoryModal">
                    <div className={classes.paper}>
                        <div className="modal fade show custom-modal">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">{'Bid history'}</h4>
                                        <Button
                                            id="bidHistory_close"
                                            className="close"
                                            onClick={() => setBidPopUp({ popup: false, id: 0 })}
                                        >
                                            <span className="material-icons">close</span>
                                        </Button>
                                    </div>
                                    {isLoading ? (
                                        <div className="loader-center-wrapper">
                                            <Loaders isLoading={isLoading} loop={1} />
                                        </div>
                                    ) : (
                                        <div className="modal-body cm-body table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{'ID'}</th>
                                                        <th scope="col">{'Bidder name'}</th>
                                                        <th scope="col">Bid Amount</th>
                                                        <th scope="col">{'Date'}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {console.log(viewProduct, 'viewProduct')}
                                                    {viewProduct?.length ? (
                                                        viewProduct.map((data, index) => (
                                                            <>
                                                                <tr>
                                                                    <td>{data.user_id}</td>
                                                                    <td>
                                                                        {' '}
                                                                        {data.user_id ===
                                                                        user.user_id
                                                                            ? user?.first_name +
                                                                              ' ' +
                                                                              user?.last_name
                                                                            : data.name_secret}{' '}
                                                                    </td>
                                                                    <td>
                                                                        {currencyFormat(
                                                                            data.proposed_amount,
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {dateTimeFormatFunction(
                                                                            data.created_at,
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={8} className="text-center">
                                                                No results found
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
                                                <h6>
                                                    {'Showing'} {search_allbidhistory.setDisp}
                                                    {' of'} {search_allbidhistory.totalRecords}
                                                </h6>
                                                <Pagination
                                                    count={Math.ceil(
                                                        search_allbidhistory.totalRecords /
                                                            search.limit,
                                                    )}
                                                    page={search.page}
                                                    onChange={onHandlePage}
                                                    siblingCount={3}
                                                    showFirstButton
                                                    showLastButton
                                                    boundaryCount={2}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default BidHistory
