import React from 'react'
import { Helmet } from 'react-helmet'
import { SITE_NAME, SITE_TAG } from '.'

const SEO = ({ title, description, keywords, url, image }) => {
    return (
        <Helmet>
            <title>
                {`${title ? title + ' | ' : ''}`} {SITE_NAME} - {SITE_TAG}
            </title>
            <meta name="title" content={`${title ? title + ' | ' : ''} ${SITE_NAME} `} />
            <meta name="description" content={description}></meta>
            <meta name="keywords" content={keywords}></meta>

            <meta property="og:url" content={url} />
            <meta property="og:title" content={`${title ? title + ' | ' : ''} ${SITE_NAME}`} />
            <meta property="og:description" content={description} />
        </Helmet>
    )
}

export default SEO
